import { Outlet } from "react-router-dom";
import StyledNavbar from "./StyledNavbar";

const SharedContent = () => {
  return (
    <>
    <h1>Products</h1>
      <Outlet />
    </>
  );
};
export default SharedContent;
