import { NavLink } from "react-router-dom"; 

function StyledNavbar() {
    return ( 
        <nav className="navbar">
            <NavLink to='/' className={({isActive})=>isActive ? 'link active' : 'link'}>Homepage</NavLink>
            <NavLink to='/about' className={({isActive})=>isActive ? 'link active' : 'link'}>About</NavLink>
            <NavLink to='/products' className={({isActive})=>isActive ? 'link active' : 'link'}>Products</NavLink>
            <NavLink to='/login' className={({isActive})=>isActive ? 'link active' : 'link'}>Login</NavLink>
        </nav>
     );
}

export default StyledNavbar;