import { Link } from "react-router-dom";

const About = () => {
  return (
    <section className='section'>
      <h2>About</h2>
    </section>
  );
};
export default About;
